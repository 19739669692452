import React from "react";
import { Link } from "gatsby";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";

import clientConfig from "../../client-config";
import ThemeContext from "../context/ThemeContext";
import { cn } from "../lib/helpers";

import * as styles from "./exhibition-hero.module.css";

const {isFuture} = require('date-fns');

const heroImage = item => {
  let img = null;

  if (item && item.largeImage && item.largeImage.asset) {
    let smallImageData = null;
    const largeImageData = getGatsbyImageData(
      item.largeImage,
      { maxWidth: 2880 },
      clientConfig.sanity
    );
    
    if (item.smallImage && item.smallImage.asset) {
      smallImageData = getGatsbyImageData(
        item.smallImage,
        { maxWidth: 1400 },
        clientConfig.sanity
      );

      const imageData = withArtDirection(largeImageData, [
        {
          media: "(max-width: 767px)",
          image: smallImageData,
        },
      ]);

      img = (
        <GatsbyImage
          className={styles.image}
          image={imageData}
          alt={item.largeImage.alt}
        />
      );
    } else {
      img = (
        <GatsbyImage
          className={styles.image}
          image={largeImageData}
          alt={item.largeImage.alt}
        />
      );
    }
  }
  return img;
};

const hero = (featuredMedia, image, isExhibitionPage, title, slug, publishedAt) => {
  let hero = null;

  if (!isExhibitionPage && !isFuture(publishedAt)) {
    hero = (
      <Link to={`/exhibitions/${slug.current}`}>
        {featuredMedia && image}
        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
      </Link>
    );
  } else {
    hero = (
      <>
        {featuredMedia && image}
        <div className={styles.title}>
          <h2>{title}</h2>
        </div>
      </>
    );
  }

  return hero;
}

const ExhibitionHero = ({
  featuredMedia, 
  slug, 
  title, 
  showNav, 
  virtualGallery, 
  isExhibitionPage,
  publishedAt
}) => {
  const image = heroImage(featuredMedia);
  const heroBlock = hero(featuredMedia, image, isExhibitionPage, title, slug, publishedAt);
  return (
    <ThemeContext.Consumer>
      {theme => (
        <div>
          {showNav && (
            <div className={cn(styles.nav, theme.dark ? styles.dark : styles.light)}>
              {virtualGallery && (
                <Link to={`/exhibitions/${slug.current}/virtual-gallery`} className={styles.navItem}>
                  <span className={styles.icon}>&#8594;</span>
                  <span>Enter the gallery</span>
                </Link>
              )}
              <Link to={`/exhibitions/${slug.current}`} className={styles.navItem}>
                <span className={styles.icon}>&#8594;</span>
                <span>View more about the exhibition</span>
              </Link>
            </div>
          )}
          {heroBlock}
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default ExhibitionHero;