import React from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import BlockText from "./block-text";

import * as styles from "./about-section-feature.module.css";

const AboutSectionFeature = props => {
  const item = props.data;

  return (
    <div key={item._key}>
      <AnchorLink to={`/about#${item.link.slug.current}`} title={item.title}>
        {item.image && (
          <img
            className={styles.image}
            src={imageUrlFor(buildImageObj(item.image))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit("crop")
              .url()}
            alt={item.image.alt}
            lazy="true"
          />
        )}
        <div className={styles.textContainer}>
          <h4 className={styles.title}>{item.title}</h4>
          <BlockText blocks={item._rawDescription} />
        </div>
      </AnchorLink>
    </div>
  );
};

export default AboutSectionFeature;