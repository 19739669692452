import React, { useState } from "react";
import { Link } from "gatsby";
import ThemeContext from "../context/ThemeContext";
import { cn } from "../lib/helpers";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import clientConfig from "../../client-config";
import BlockText from "./block-text";
import * as styles from "./exhibition-map.module.css";

const renderImage = image => {
  let img = null;
  if (image && image.asset) {
    const imageData = getGatsbyImageData(
      image,
      { maxWidth: 1800 },
      clientConfig.sanity
    );

    img = (
      <GatsbyImage
        image={imageData}
        alt={image.alt}
      />
    );
  }
  return img;
};

const ExhibitionMap = ({ rooms, slug }) => {
  const [activeRoom, setActiveRoom] = useState(1);

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div className={styles.root}>
          <div className={styles.aspectRatioWrapper}>
            <div className={styles.title}>
              <h4>Gallery Map</h4>
              <p>Explore each room to reveal the <br/>artwork on display.</p>
            </div>
            {/* <Map className={styles.map} /> */}
            <svg className={cn(styles.map, theme.dark ? styles.dark : styles.light)} version="1.1" id="map" x="0px" y="0px" viewBox="0 0 1618 1618" style={{enableBackground: 'new 0 0 1618 1618'}}>
              <path style={{fill:'none',stroke:'#666666',strokeMiterlimit:'10'}} d="M809,1481.8c371.6,0,672.8-301.2,672.8-672.8
                c0-371.6-301.2-672.8-672.8-672.8c-371.6,0-672.8,301.2-672.8,672.8C136.1,1180.6,437.4,1481.8,809,1481.8z"/>
              <g onMouseEnter={() => setActiveRoom(12)} className={activeRoom === 12 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M920.3,1H697.8v222.5h222.5V1z"/>
                <path d="M800.2,96c-1.1,3.9-3.5,6.7-7.6,7.6v1.4c4.2-0.9,6.4-3.4,7.6-6.4v4.9V124h1.4V96H800.2z M825.7,124v-1.3h-16.4
                  c0.2-1.6,2-3.3,5.5-5.9l3-2.2c4.8-3.6,7.5-6.5,7.5-10.7c0-4.9-3.4-8.3-8.6-8.3c-5.3,0-9.1,4.2-9.1,9.6v0.5h1.4v-0.5
                  c0-4.9,3.2-8.3,7.6-8.3c4.2,0,7.1,2.9,7.1,7c0,3.9-2.6,6.5-6.1,9l-4.4,3.2c-4.2,3.1-5.8,5.4-6,7.9H825.7z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(6)} className={activeRoom === 6 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M697.7,1617h222.5v-222.5H697.7V1617z"/>
                <path d="M809.7,1517.8c5.3,0,8.9-4.1,8.9-9.4c0-5.3-3.4-9.1-8.6-9.1c-3.4,0-6.7,1.7-8.2,5.7c0-0.8,0-1.5,0.1-2.2
                  c0.4-7.2,3.4-12.8,9.2-12.8c3.2,0,5.4,2.2,5.9,5.6h1.4c-0.5-4.3-3.3-7-7.4-7c-3.9,0-6.7,2.2-8.5,5.6c-3,5.4-3.4,15.3,0.2,20.1
                  C804.4,1516.6,806.7,1517.8,809.7,1517.8z M809.7,1516.5c-4.2,0-7.5-3.4-7.5-8.1c0-4.5,3.3-7.8,7.7-7.8c4.6,0,7.3,3.2,7.3,7.8
                  C817.2,1513,814.4,1516.5,809.7,1516.5z"/>
              </g>
              <Link to={`/exhibitions/${slug.current}/virtual-gallery`}>
                <g>
                  <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M809,1231.2c233.1,0,422.1-189,422.1-422.2
                    c0-233.1-189-422.1-422.1-422.1c-233.1,0-422.1,189-422.1,422.1C386.8,1042.2,575.8,1231.2,809,1231.2z"/>
                  <path d="M670.1,821.7v-37.8h23.1v1.8H672v15.9h20.3v1.8H672v16.6h21.8v1.8H670.1z M701.3,821.7v-25.9h1.8v2.9v3.1
                    c1.5-3.2,4.5-6.6,9.8-6.6c5.1,0,9,3.5,9,10.3v16.2h-1.8v-16c0-5-1.9-8.8-7.5-8.8c-5.5,0-9.5,5.1-9.5,10.4v14.5H701.3z M732.5,816.8
                    v-19.2h-5.5v-1.8h5.5v-7.9h1.9v7.9h6v1.8h-6v19.3c0,3.1,1.7,3.8,4.1,3.8c1,0,1.7-0.2,2.4-0.4v1.6c-1,0.3-1.8,0.4-2.8,0.4
                    C734.9,822.4,732.5,820.9,732.5,816.8z M744.6,808.6c0-7.8,5.2-13.4,11.8-13.4c7,0,11,5.2,11,13.1v0.5h-20.9
                    c0,7.7,3.9,11.9,10.2,11.9c4.9,0,8.2-2.6,8.8-6.8h1.9c-0.9,5-4.6,8.4-10.7,8.4C749.6,822.4,744.6,817.4,744.6,808.6z M765.4,807.1
                    c-0.2-6.1-3.4-10.3-9.1-10.3c-5.5,0-9.3,4.3-9.8,10.3H765.4z M774.9,821.7v-25.9h1.8v2.3v3.8c1.5-3.7,4.9-6.8,9.4-6.8
                    c0.8,0,1.3,0.1,1.8,0.1v1.7c-0.5-0.1-1.2-0.1-1.8-0.1c-5.3,0-9.4,4.8-9.4,10.6v14.3H774.9z M805.5,824.7h1.9
                    c0.4,4.5,3.8,7.5,8.9,7.5c5.9,0,9.6-3.3,9.6-9.8v-3v-4.2c-1.3,3.6-4.8,6.9-10.1,6.9c-6.4,0-11.5-5.6-11.5-13.5
                    c0-8.2,5.3-13.3,11.6-13.3c5.9,0,8.7,3.8,10,6.6v-3.4v-2.6h1.9v26.4c0,7.4-4.6,11.6-11.3,11.6C810.1,833.8,805.9,830.2,805.5,824.7
                    z M825.8,808.4c0-6.6-3.7-11.5-9.8-11.5c-5.8,0-9.9,4.5-9.9,11.6c0,6.9,4,11.8,9.8,11.8C822.2,820.3,825.8,815.2,825.8,808.4z
                    M835.4,815.1c0-4.3,3.2-6.7,8-7.5l5.2-0.8c2.4-0.4,4.9-1,4.9-3.5c0-3.8-2.8-6.4-7.3-6.4c-5.7,0-7.7,3.5-8,6.9h-1.9
                    c0.5-4.7,3.9-8.5,9.9-8.5c5.9,0,9.2,3.7,9.2,8.7v17.8h-1.9v-2.2v-3.2c-1.4,3.1-4.7,6-9.7,6C838.8,822.4,835.4,819.4,835.4,815.1z
                    M853.4,810.9v-4.7c-0.3,0.9-1.8,1.6-4,1.9l-5.8,1c-3.8,0.6-6.4,2.4-6.4,6c0,3.3,2.6,5.7,6.6,5.7
                    C849.9,820.8,853.4,815.9,853.4,810.9z M866.2,783.9v37.8h-1.8v-37.8H866.2z M877.4,783.9v37.8h-1.8v-37.8H877.4z M884.9,808.6
                    c0-7.8,5.2-13.4,11.8-13.4c7,0,11,5.2,11,13.1v0.5h-20.9c0,7.7,3.9,11.9,10.2,11.9c4.9,0,8.2-2.6,8.8-6.8h1.9
                    c-0.9,5-4.6,8.4-10.7,8.4C890,822.4,884.9,817.4,884.9,808.6z M905.8,807.1c-0.2-6.1-3.4-10.3-9.1-10.3c-5.5,0-9.3,4.3-9.8,10.3
                    H905.8z M915.3,821.7v-25.9h1.8v2.3v3.8c1.5-3.7,4.9-6.8,9.4-6.8c0.8,0,1.3,0.1,1.8,0.1v1.7c-0.5-0.1-1.2-0.1-1.8-0.1
                    c-5.3,0-9.4,4.8-9.4,10.6v14.3H915.3z M935.9,833.1l5-11.8l-10.6-25.4h2l7.3,17.6c0.7,1.7,1.5,3.6,2.3,5.3c0.7-1.8,1.5-3.7,2.3-5.5
                    l7.3-17.5h1.9l-15.5,37.3H935.9z"/>
                </g>
              </Link>
              <g onMouseEnter={() => setActiveRoom(1)} className={activeRoom === 1 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMmiterlimit:'10'}} d="M1309.3,164.8L1116.7,53.6l-111.2,192.7l192.7,111.2
                  L1309.3,164.8z"/>
                <path d="M1158.9,190.2c-1.1,3.9-3.5,6.7-7.6,7.6v1.4c4.2-0.9,6.4-3.4,7.6-6.4v4.9v20.4h1.4v-28H1158.9z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(11)} className={activeRoom === 11 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M501.3,53.6L308.6,164.9l111.2,192.7l192.7-111.2L501.3,53.6z"
                  />
                <path d="M456.2,190.2c-1.1,3.9-3.5,6.7-7.6,7.6v1.4c4.2-0.9,6.4-3.4,7.6-6.4v4.9v20.4h1.4v-28H456.2z M470.1,190.2
                  c-1.1,3.9-3.5,6.7-7.6,7.6v1.4c4.2-0.9,6.4-3.4,7.6-6.4v4.9v20.4h1.4v-28H470.1z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(2)} className={activeRoom === 2 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M1564.4,501.2l-111.2-192.7l-192.7,111.2l111.2,192.7
                  L1564.4,501.2z"/>
                <path d="M1421.1,472.2v-1.3h-16.4c0.2-1.6,2-3.3,5.5-5.9l3-2.2c4.8-3.6,7.5-6.5,7.5-10.7c0-4.9-3.4-8.3-8.6-8.3
                  c-5.3,0-9.1,4.2-9.1,9.6v0.5h1.4v-0.5c0-4.9,3.2-8.3,7.6-8.3c4.2,0,7.1,2.9,7.1,7c0,3.9-2.6,6.5-6.1,9l-4.4,3.2
                  c-4.2,3.1-5.8,5.4-6,7.9H1421.1z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(4)} className={activeRoom === 4 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M1453.2,1309.3l111.2-192.7l-192.7-111.2l-111.2,192.7
                  L1453.2,1309.3z"/>
                <path d="M1417.6,1170.7v-7.8h5.2v-1.3h-5.2v-18.9h-1.4l-13.4,18.9v1.3h13.3v7.8H1417.6z M1404.4,1161.6c0.4-0.6,0.8-1.2,1.2-1.7
                  l9-12.7l1.4-2v16.4H1404.4z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(5)} className={activeRoom === 5 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M1116.7,1564.5l192.7-111.2l-111.2-192.7l-192.7,111.2
                  L1116.7,1564.5z"/>
                <path d="M1157.6,1424.4c5.7,0,9.4-4,9.4-9.6c0-5.8-3.6-9.3-8.5-9.3c-3.5,0-6.1,1.8-7.6,4c0.1-1.1,0.3-2.4,0.5-3.4l1.2-9h12.9v-1.3
                  h-14l-2,15.5h1.4c1.4-2.5,4-4.6,7.6-4.6c4.4,0,7.2,3,7.2,8c0,4.8-2.9,8.3-7.9,8.3c-4,0-7-2.5-7.4-6.3h-1.4
                  C1149.2,1421.4,1152.7,1424.4,1157.6,1424.4z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(7)} className={activeRoom === 7 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M308.7,1453.3l192.7,111.2l111.2-192.7l-192.7-111.2
                  L308.7,1453.3z"/>
                <path d="M456.2,1432.9c0.5-9.6,3.4-18.5,10.4-26.6v-1.4h-17.4v1.3h16.1c-0.6,0.6-1.1,1.3-1.6,2c-5.7,7.4-8.4,14.8-8.9,24.7H456.2z"
                  />
              </g>
              <g onMouseEnter={() => setActiveRoom(3)} className={activeRoom === 3 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M1617,920.3V697.9h-222.5v222.5H1617z"/>
                <path d="M1507.1,822c5,0,9.1-3.2,9.1-8c0-3.6-2.4-6.3-6.2-7.2c3.8-1.2,5.4-3.7,5.4-6.6c0-4.4-3.5-7.2-8.2-7.2c-4.9,0-8.6,3.2-9,8
                  h1.4c0.3-4.1,3.3-6.7,7.5-6.7c4.2,0,6.8,2.3,6.8,5.9c0,3.2-2.8,5.9-6.7,5.9h-2.9v1.3h2.8c4.5,0,7.4,2.8,7.4,6.5
                  c0,4.1-3.3,6.7-7.6,6.7c-4.6,0-7.7-2.6-8-6.6h-1.4C1498,818.9,1501.6,822,1507.1,822z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(9)} className={activeRoom === 9 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M1,697.8v222.5h222.5V697.8H1z"/>
                <path d="M110.7,822c4.1,0,6.9-2.2,8.5-5.7c2.1-4.6,2.6-13.2,0.4-18.1c-1.4-3.1-4-5.3-8-5.3c-5,0-8.9,3.8-8.9,9.3
                  c0,4.8,3.7,8.9,8.6,8.9c3.4,0,6.8-1.8,8.2-5.9v1.6c0,9.6-3.4,13.9-8.8,13.9c-3.4,0-5.9-2-6.4-5.4h-1.4
                  C103.4,819.4,106.4,822,110.7,822z M111.6,809.8c-4.4,0-7.4-3.4-7.4-7.6c0-4.7,3-8,7.4-8c4.3,0,7.5,3.3,7.5,8
                  C119,806.4,115.8,809.8,111.6,809.8z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(10)} className={activeRoom === 10 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M164.8,308.7L53.6,501.3l192.7,111.2l111.2-192.7L164.8,308.7z
                  "/>
                <path d="M197,442.4c-1.1,3.9-3.5,6.7-7.6,7.6v1.4c4.2-0.9,6.4-3.4,7.6-6.4v4.9v20.4h1.4v-28H197z M214,471c5.8,0,9.3-5.6,9.3-14.5
                  c0-8.9-3.5-14.6-9.3-14.6c-5.8,0-9.4,5.6-9.4,14.6C204.7,465.4,208.2,471,214,471z M214,469.6c-5.2,0-7.9-5-7.9-13.2
                  c0-8.1,2.7-13.2,7.9-13.2c5.2,0,7.9,5.1,7.9,13.2C221.9,464.6,219.2,469.6,214,469.6z"/>
              </g>
              <g onMouseEnter={() => setActiveRoom(8)} className={activeRoom === 8 ? styles.isActive : ''}>
                <path style={{stroke:'#666666',strokeMiterlimit:'10'}} d="M53.6,1116.7l111.2,192.7l192.7-111.2l-111.2-192.7
                  L53.6,1116.7z"/>
                <path d="M207.2,1163.2c5.8,0,9.4-3.2,9.4-7.8c0-3.4-2.2-6.4-6.4-7.3c3.7-0.9,5.7-3.7,5.7-6.7c0-4-3.5-7.3-8.6-7.3
                  c-5.2,0-8.6,3.3-8.6,7.3c0,3,2,5.8,5.6,6.7c-4.2,0.8-6.7,3.9-6.7,7.3C197.4,1160.1,201.4,1163.2,207.2,1163.2z M207.2,1147.6
                  c-4.3,0-7.2-2.5-7.2-6.1c0-3.4,2.8-6,7.2-6c4.4,0,7.2,2.6,7.2,6C214.4,1145.1,211.4,1147.6,207.2,1147.6z M207.2,1161.9
                  c-5.1,0-8.3-2.6-8.3-6.5c0-4.1,3.4-6.6,8.3-6.6c4.9,0,7.9,2.5,7.9,6.6C215.1,1159.4,212.2,1161.9,207.2,1161.9z"/>
              </g>
            </svg>

            {rooms.map((room, index) => (
              <div key={room._key} className={cn(styles.information, activeRoom === (index + 1) ? styles.isActive : '')}>
                {renderImage(room.image)}
                <div className={styles.text}>
                  <BlockText blocks={room._rawText} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default ExhibitionMap;