import React, { useEffect } from "react";
import Marquee3k from 'marquee3000';
import BlockText from "./block-text";
import * as styles from "./marquee.module.css";

const Marquee = props => {
  const { text } = props;

  useEffect(() => {
    Marquee3k.init();
  }, []);

  return (
    <div className={styles.root}>
      <div className="marquee3k" 
        data-speed="0.6"
        data-pausable="true">
          <BlockText blocks={text} />
      </div>
    </div>
  );
};

export default Marquee;